.edit-subscription-class {
    .form-button {
        margin-top: 1rem;
    }

    .relations-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    
    .over-eighteen-true {
        grid-template-columns: 1fr 1fr !important;
    }
    
    .relations-container svg {
        width: 100%;
    }
    
    .relation {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    .relation label {
        text-align: center;
    }
    
    .relation label > div {
        margin-top: -1rem;
    }
    
    .relation:nth-of-type(3) svg {
        border: 0 !important;
        padding: 0;
    }
    
    .relation input[type="radio"] {
        appearance: none;
        height: 0;
        opacity: 0;
        padding: 0;
        margin: 0;
    }
}

.popup{
    background-color:rgba(240, 240, 240, 0.863);
    width:100%;
    height: 100%;
    position:fixed;
    z-index:999;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &__container {
        display: flex;
        flex-direction: column;
        margin-bottom: 20%;
        background-color: white;
        max-width: 300px;
        border-radius: 10px;
        box-shadow: 0 4px 20px rgba(0,0,0,0.18);
        padding: 1rem;
    }
    
    &__content {
        padding: 2rem;
        box-sizing: border-box;
        width: 100%;
        font-size: 13px;
    }

    &__close {
        display: inline-block;
        align-self: flex-end;
        padding: 0;
        border-radius: 50%;
        font-size: .5rem;
        width: 1rem;
        height: 1rem;
        background-color: transparent;
        border: 1px solid black;
        color: black;
    }
}