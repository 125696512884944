.subscription-list-item {
    &__content{
        direction: ltr !important;
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: right;
        flex-wrap: nowrap;
        font-size: 12px;
    }
    &__data {
        text-align: left;
        &:nth-of-type(2) {
            font-weight: bold;
            text-align: right;
            padding-right: 1rem;
        }
        &-container {
        background-color: #E6E6E685;
        box-shadow: 0 4px 20px rgba(0,0,0,0.18);
        border-radius: 10px;
        padding: .5rem 1rem;
        h3 {
            margin-block: .2rem;
        }
        }
    }
}
