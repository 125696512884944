.subscription-list {
    list-style: none;
    margin: 0;
    // margin: auto;
    padding: 0;
    width: 100%;
    
    &-item {
        width: 100%;
        &__button {
            width: 20px;
            position: relative;
            bottom: -27px;
            left: -3px;
            background: gray;
            border: none;
            // accent-color: #202867;
        }
    }
}