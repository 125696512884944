
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.page-layout {
  font-family: rubik;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  margin: auto;
  text-align: center;
  
  .HBSFC-logo {
    width: 100px;
    margin-block: 1rem 2rem;
  }
}

label {
  width: 90%;
  display: inline-block;
  direction: rtl;
  text-align: right;
  font-size: .8rem;
}

.input-rtl {
  direction: rtl;
}

.error-validator {
  margin-right: .5rem;
  font-size: .7rem;
  color: red;
}

input {
  width: 90%;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid gray;
  padding: .5rem;
  font-size: 1.2rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=checkbox]{
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

button {
  padding: .6rem 5rem;
  font-size: 1.3rem;
  color: white;
  border: none;
  border-radius: 5px;
  background-color: #202867;
  cursor: pointer;
  &:disabled {
    background-color: #979797;
    cursor: not-allowed;
  }
}